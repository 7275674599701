
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Category extends Vue {
  @Prop() file!: string;
  @Prop() title!: string;
}
